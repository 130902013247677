import {useSelector} from "react-redux";
import { useDispatch } from "react-redux";
import { APIURL, addCommas, checkcurrency } from '../config/Index'
import { setBonus } from "../store/user";
import CountdownTimer from './CountdownTimer'
import QRCodeImg from "react-qr-code"
import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'ຍອດໂອນ',
      2:'ຮັບເຄດິດ',
      3:'ເມື່ອໂອນແລ້ວກົດປຸ່ມ "ຍືນຢັນຝາກ" ດ້ານລຸ່ມ',
      4:'ຍືນຢັນຝາກ',
    },
    thai:{
      1:'ยอดฝาก',
      2:'รับเครดิต',
      3:'เมื่อโอนแล้วกรุณากด "ยืนยัน" ด้านล่าง',
      4:'ยืนยันฝาก',
    }
  }
function Profile({depositConfirm}) {
    const dispatch = useDispatch()
    const setbonusid =(id) => {
        dispatch(setBonus(id))
    }
    const { bonus, bonusitems, amount, depositpro } = useSelector(state => state.user)
    console.log('bonus-redux', bonus)
    return (
        <div className='tab-pane fade active show'>
                                         <div className="griddps">
                                    <div className="modalspanbox mt-3">
                                  <div className="txtbonus">
                                   
                                   <h2>คลิ๊ก รับโบนัส</h2>
                                   ก่อนยืนยันฝาก
                                  </div>
 <div className="d-block pt-4"></div>               

<div className='d-flex justify-content-around'>
{`${bonus}` === `0` ? <div className="proactive">ไม่รับโบนัส</div> : <div onClick={()=> setbonusid('0')} className="pronormal">ไม่รับโปร</div>}

{
      bonusitems && bonusitems.filter(x=>parseFloat(x.pmin) <= parseFloat(amount)).map(x=> <span key={x.id} className=''>
                                     
      {(`${bonus}` === `${x.id}`) ? <div className="proactive">รับโบนัส {x.pbonusname}</div> : <div className="pronormal" onClick={()=> setbonusid(x.id)}>โบนัส {x.pbonusname}</div>}
      </span>
    )
}
                                    
                        
                                 
                                </div>
                                       
                                        </div>
                                       
                                        {
                                            parseInt(depositpro?.bonus) > 0 && <div className='xxx'>
                                          
                                            <div className="text-center pb-2 pt-2 modalspanbox">รายละเอียดโปรโมชั่น { depositpro?.des}</div>
                                            <div className="text-center txtheadpro">เงื่อนไขการรับโปรฯ ดังนี้</div>
                        
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">1. ฝาก</span> <span className="txtleft">{addCommas(parseFloat(amount).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">2. รับเครดิตเพิ่มรวมเป็น</span> <span className="txtleft">{addCommas(parseFloat(depositpro?.amountvalue).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">3. {depositpro?.pturntype}</span> <span className="txtleft">{addCommas(parseFloat(depositpro?.withdraw).toFixed(0))}</span></div>
                                            <div className="d-flex justify-content-between pt-2"><span className="txtleft">4. ถอนได้สูงสุด</span> <span className="txtleft">{depositpro?.maxwithdraw > 0 ? addCommas(parseFloat(depositpro?.maxwithdraw)) :'ไม่จำกัด'}</span></div>
                                            <div className="d-flex justify-content-between pt-3"><span className="txtleftx">หน่วย</span> <span className="txtleftxx">บาท (THB)</span></div>
                                        </div>
                                        }
                                        
                                        </div>
                                        {
                                           parseInt(depositpro?.bonus) === 0 &&  <div className="text-center txtheadprotop">คุณไม่รับโปร</div>
                                        }
            <button type="button" onClick={depositConfirm} className="moneyBtn"> {lgtext[LG]['4']}</button>
                                    </div>
    );
}

export default Profile;
