import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'

//import React from "react";
import { APIURL, WEBTOKEN, addCommas, LG } from '../config/Index'
import io from "socket.io-client"

const nio = io('https://notifyskylot44.net')

export const memberlogin = createAsyncThunk('appAgent/addData', async (provider) => {
    const data = await axios.post(`${APIURL}/members/login`, provider)
    return {
      item:data.data.item
    }
})


export const bannerpopup = createAsyncThunk('appAgent/bannerpopup', async (provider) => {
  if(LG === 'thai') {
    const data = await axios({
      method: "get",
      url: `${APIURL}/banner/findallwebpupopthai`,
      params:{webtoken:WEBTOKEN, ...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      items:data.data.items
    }
  } else {
    const data = await axios({
      method: "get",
      url: `${APIURL}/banner/findallwebpupop`,
      params:{webtoken:WEBTOKEN, ...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      items:data.data.items
    }
  }
  
})

export const memberregister = createAsyncThunk('appAgent/memberregister', async (provider) => {

  try {
    const data = await axios.post(`${APIURL}/members/register`, provider)
    return {
      errcode:0,
      item:data.data.item
    }
  } catch (error) {
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})

export const memberchangpass = createAsyncThunk('appAgent/memberchangpass', async (provider) => {

  try {

    const data = await axios({
      method: "post",
      url: `${APIURL}/members/changepass/1`,
      data:{...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    return {
      errcode:'changepass',
      item:data.data.item
    }
  } catch (error) {
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})


export const createqrcode = createAsyncThunk('appAgent/createqrcode', async (provider) => {
  try {

    const data = await axios({
      method: "post",
      url: `${APIURL}/statement/createqrcode`,
      data:{...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    return {
      errcode:0,
      defaultValues:data.data.item
    }
  } catch (error) {
    return {
      errcode:error.response.status
    }
  }
})


export const memberwithdraw = createAsyncThunk('appAgent/memberwithdraw', async (provider) => {
  try {

    const data = await axios({
      method: "post",
      url: `${APIURL}/withdraw/memberwithdraw`,
      data:{...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    return {
      errcode:0,
      item:data.data.item
    }
  } catch (error) {
    return {
      errcode:error.response.status
    }
  }
})

export const getbalance = createAsyncThunk('appAgent/getbalance', async (provider) => {
  //const data = await axios.get(`${APIURL}/members/balance`, provider)
  try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/members/balance`,
      
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      item:data.data.item
    }
  }catch(error){
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})

export const checkcommission = createAsyncThunk('appAgent/checkcommission', async (provider) => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/commission/1/checkcommission`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    item:data.data.item
  }
})

export const commissionwithdraw = createAsyncThunk('appAgent/commissionwithdraw', async (provider) => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/commission/1/withdraw`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    item:data.data.item,
    commission:data.data.commission,

  }
})

export const checkcashback = createAsyncThunk('appAgent/checkcashback', async (provider) => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/cashback/1/checkcashback`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    item:data.data.item
  }
})

export const cashbackwithdraw = createAsyncThunk('appAgent/cashbackwithdraw', async (provider) => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/cashback/1/withdraw`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    item:data.data.item,
    cashback:data.data.cashback,

  }
})


export const affwithdraw = createAsyncThunk('appAgent/affwithdraw', async (provider) => {
  try{
  const data = await axios({
    method: "get",
    url: `${APIURL}/affiliate/1/withdraw`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    errcode:200,
    item:data.data.item
  }
}catch(error){
  return {
    errcode:error.response.status,
    item:{}
  }
}
})

export const gamelist = createAsyncThunk('appAgent/gamelist', async () => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/members/gamelist`,
    params:{webtoken:WEBTOKEN},
  });
  return {
    item:data.data.items
  }
})

export const promotionlist = createAsyncThunk('appAgent/promotionlist', async () => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/promotion/listbunus`,
    params:{webtoken:WEBTOKEN},
  });
  return {
    items:data.data.items
  }
})


export const promotionlistlogin = createAsyncThunk('appAgent/promotionlistlogin', async (item) => {
  try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/promotion/listbunuslogin`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      items:data.data.items
    }
  }catch(error){
    return {
      errcode:error.response.status,
      items:[]
    }
  }
  
})

export const bannerlist = createAsyncThunk('appAgent/bannerlist', async () => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/banner/findallweb`,
    params:{webtoken:WEBTOKEN},
  });
  return {
    items:data.data.items
  }
})

export const getwebinfo = createAsyncThunk('appAgent/getwebinfo', async () => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/agent/getinfo/${WEBTOKEN}`
  });
  return {
    item:data.data.item
  }
})

export const bankregister = createAsyncThunk('appAgent/bankregister', async () => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/bank/bankregister`,
    params:{webtoken:WEBTOKEN},
  });
  return {
    item:data.data.items
  }
})



export const opengame = createAsyncThunk('appAgent/opengame', async (item) => {
  try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/members/opengame`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      item:data.data.item
    }
  }catch(error){
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})


export const gamedetail = createAsyncThunk('appAgent/gamedetail', async (item) => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/members/gamedetail/${item.brandcode}`,
    params:{webtoken:WEBTOKEN, ...item},
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    items:data.data.items,
    headphoto:data.data.headphoto,
  }
})

export const bankdeposit = createAsyncThunk('appAgent/bankdeposit', async (item) => {
  try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/bank/bankdeposit`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      items:data.data.items,
      item:data.data.item,
    }
  }catch(error){
    return {
      errcode:error.response.status,
      items:[]
    }
  }

})

export const gethistory = createAsyncThunk('appAgent/gethistory', async (item) => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/statement/betlist`,
    params:{webtoken:WEBTOKEN, ...item},
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    item:data.data.items,
    meta:data.data.meta,
    tab:data.data.tab,
  }
})

export const checkqrcode = createAsyncThunk('appAgent/checkqrcode', async (item) => {
  try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/statement/checkqrcode`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      item:data.data.item
    }
  }catch(error){
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})

export const checkwithdraw = createAsyncThunk('appAgent/checkwithdraw', async (item) => {
  try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/withdraw/checkwithdraw`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      item:data.data.item
    }
  }catch(error){
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})

export const getaffdata = createAsyncThunk('appAgent/getaffdata', async (item) => {
  try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/members/getaffdata`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      item:data.data.item
    }
  }catch(error){
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})

export const confirmqrcode = createAsyncThunk('appAgent/confirmqrcode', async (item) => {
  const data = await axios({
    method: "get",
    url: `${APIURL}/statement/${item.sid}/confirmqrcode`,
    params:{webtoken:WEBTOKEN, ...item},
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    item:data.data.item
  }
})

export const confirmqrcodepost = createAsyncThunk('appAgent/confirmqrcodepost', async (item) => {
  const data = await axios({
    method: "put",
    url: `${APIURL}/statement/${item.sid}/confirmqrcodepost`,
    data:{webtoken:WEBTOKEN, ...item},
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    item:data.data.item,
    items_auto:data.data.items_auto
  }
})

export const rejectqrcodepost = createAsyncThunk('appAgent/rejectqrcodepost', async (item) => {
  const data = await axios({
    method: "put",
    url: `${APIURL}/statement/${item.sid}/rejectqrcodepost`,
    data:{webtoken:WEBTOKEN, ...item},
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return {
    item:data.data.item,
    items_auto:data.data.items_auto
  }
})

const checkpromotion = (bonus, state) => {
  /*
  pmin: { type: Number, default: 0 }, //ฝากขั้นต่ำ
    pmax: { type: Number, default: 0 }, // ให้สูงสุด
    pturn: { type: Number, default: 0 }, // ยอดเทอริน
    pturntype: { type: Number, default: 0 }, // คิดโปรจาก 0= จากยอดเทิร์น , 1= จากกำไร
    pbonus: { type: Number, default: 0 }, // ให้โบนัส
    pbonustype: { type: Number, default: 0 }, // คิดเป็น % หรือ จำนวนเงิน
    pwithdrawpercen: { type: Number, default: 5 }, // ถอนได้สูงสุดกี่เท่าของยอดฝาก
    newmember: { type: Number, default: 0 }, // 0 = ลูกค้าเก่า 1= ลูกค้าใหม่
    qty: { type: Number, default: 0 }, // 0 = รับไม่อั้น มากกว่า 0 คือรับตามจำนวนตั้ง
    pwithdrawamount: { type: Number, default: 100 }, // ถอนได้สูงสุดกี่บาท
  */
  const { bonusitems, amount } = state
  const checkdata = bonusitems.find(x=>`${x.id}` === `${bonus}`)
  if(checkdata){
      const {pbonus,pturn,pturntype,pmax,pmin,id,pwithdrawpercen,pwithdrawamount,pbonustype, pturnamount} = checkdata
      if(parseFloat(amount) >= parseFloat(pmin)) {
        const bonus = (parseFloat(amount)*parseFloat(pbonus))/100
        const nbonus = parseFloat(bonus) > parseFloat(pmax) ? pmax:bonus
        const withdraw = (parseFloat(amount)+parseFloat(nbonus))*parseFloat(pturn)
        const amountvalue = parseFloat(amount) + parseFloat(nbonus)
        let maxwithdraw = parseFloat(amount) * parseFloat(pwithdrawpercen)
        // เช็คว่ายอด ถอนได้อันไหนมากกว่า เอาอันนนั้น
        if(parseFloat(maxwithdraw) < parseFloat(pwithdrawamount)) {
          maxwithdraw = parseFloat(pwithdrawamount)
        }
        let bonuslast = nbonus
        let amountvaluelast = amountvalue
        let withdrawlast = withdraw
        const checkbonus = pbonustype === 1 ? '%' : 'บาท'
        let des = `ฝาก ${pmin} รับ ${pbonus} ${checkbonus} สูงสุด ${addCommas(parseFloat(pmax).toFixed(0))}`
        
        if(parseInt(pbonustype) === 0) {
          // กรณีคิดเป็นจำนวนเงิน
          bonuslast = pmax
          amountvaluelast = pmax
          withdrawlast = pturnamount
          
          des = `ฝาก ${pmin} รับเครดิต ${addCommas(parseFloat(pmax).toFixed(0))}`
        }

        if(parseFloat(pturnamount)>0) {
          withdrawlast = pturnamount
        } else {
          withdrawlast = pturnamount
        }
        
        const dataall = {
          bonus : bonuslast,
          fkpromotion:id,
          amountvalue:amountvaluelast,
          withdraw:withdrawlast,
          maxwithdraw, // ถอนได้สูงสุดกี่บาท
          pturntype: pturntype === 1 ? 'ทำยอดเทิร์น' : 'ทำยอดเครดิต',
          detail:checkdata,
          des
        }
       
        return dataall
      }else{
        return {bonus:0}
      }
     
  }else{
    return {bonus:0}
  }
}

const initialState = {
    idcookie:Math.random(),
    cashbackalert: false,
    commissionalert: false,
    isLogged: false,
    isLoading:false,
    isLoadingLogin:false,
    isloadingbannerp:false,
    isloadinggame:false,
    isloadingbanner:false,
    isLoadinghis:false,
    btnLoading:false,
    notdeposit:false,
    statusbank:1,
    headstep:1,
    proid:null,
    depositstep:1,
    depositpro:{},
    amount:0,
    bonus:'0',
    gwayz:'',
    errcode:0,
    webinfo:{},
    cashback:{},
    commission:{},
    meta:{},
    banneritems:[],
    mtimestamp:+ Date.now(),
    item:{balance:0, mobile:'********'},
    gameitems:[],
    betitems:[],
    banneritemspup:[],
    deposititems:[],
    bonusitems:[],
    gamedetailitems:[],
    currency:{},
    headphoto:'',
    bankitems:[],
    bankregis:[],
    accountdeposit:{},
    accountwithdraw:{},
    slipphoto:null,
    slipexten:null,
    affcode:null,
    username:null,
    htmlall:'',
    affitem:{}
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isLogged = true;
        },
        setHeadStep: (state, action) => {
          state.headstep = action.payload
        },
        setPromotion: (state, action) => {
       
          state.proid = action.payload.id
          state.depositpro = {...state.depositpro, bonus:action.payload.bonus}
        },
        setAffcode: (state, action) => {
          localStorage.setItem('affcode', action.payload)
          state.affcode = action.payload
        },
        setslipexten: (state, action) => {
          state.slipexten = action.payload
        },
        setslipphoto: (state, action) => {
          state.slipphoto = action.payload
        },
        closecashbackalert: (state, action) => {
          state.cashbackalert = false
        },
        closecommissionalert: (state, action) => {
          state.commissionalert = false
        },
        setAmount: (state, action) => {
          state.amount = action.payload
        },
        setBonus: (state, action) => {
          
          const data = checkpromotion(action.payload, state)
          state.depositpro = data
          state.bonus = action.payload
        },
        setStep: (state, action) => {
          state.depositstep = action.payload.step
          state.accountdeposit = action.payload.accountdeposit
          state.accountwithdraw = action.payload.accountwithdraw
        },
        logout: (state, action) => {
            state.isLogged = false;
            localStorage.removeItem('access_token')
            localStorage.removeItem('username')
        },
        getusername: (state, action) => {
          state.username = localStorage.getItem('username');
      },
      setnotdeposit:(state) => {
        state.notdeposit = false
      },
        checkmemberlogin: (state, action) => {
          state.isLogged = localStorage.getItem('access_token')?.length > 0;
      },
    },
    // .pending // เมือกำลังโหลด
  // .fulfilled // เมื่อโหลดเสร็จ
  // .rejected // เมื่อโหลดไม่สำเร็จหรือมีข้อผิดพลาด
  extraReducers: builder => {
    builder
      .addCase(memberlogin.pending, (state) => {
        state.isLoadingLogin = true
        state.item = {}
      })
      .addCase(memberlogin.fulfilled, (state, action) => {
        state.isLoadingLogin = false
        //state.isLogged = true
        state.item = action.payload.item  
        if (action.payload.item.access_token) {
          state.isLogged = true
          localStorage.setItem('access_token', action.payload.item.access_token)
          localStorage.setItem('username', action.payload.item.username)
          window.location.assign("/")
        }
        //localStorage.setItem('access_token', action.payload.item.access_token)
        //localStorage.setItem('username', action.payload.item.username)
      })
      .addCase(getbalance.pending, (state) => {
        state.isLoading = true
      })
    
      .addCase(memberchangpass.pending, (state) => {
        state.isLoading = true
      })
      .addCase(memberchangpass.fulfilled, (state, action) => {
        state.isLoading = false
        state.errcode = action.payload.errcode
      })
      .addCase(getbalance.fulfilled, (state,action) => {
        state.isLoading = false
        if(action.payload.errcode === 401) {
            state.isLogged = false;
            localStorage.removeItem('access_token')
            localStorage.removeItem('username')
        } else {
          state.item = {...state.item, ...action.payload.item}
        }
        
      })
      .addCase(getaffdata.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getaffdata.fulfilled, (state,action) => {
        state.isLoading = false
        if(action.payload.errcode === 401) {
            state.isLogged = false;
            localStorage.removeItem('access_token')
            localStorage.removeItem('username')
        } else {
          state.affitem = {...state.item, ...action.payload.item}
        }
      })
      .addCase(bannerlist.pending, (state) => {
        state.isloadingbanner = true
      })
      .addCase(bannerlist.fulfilled, (state,action) => {
        state.isloadingbanner = false
        state.banneritems = action.payload.items
      })

      .addCase(bannerpopup.pending, (state) => {
        state.isloadingbannerp = true
        state.showpop = false
      })
      .addCase(bannerpopup.fulfilled, (state,action) => {
        state.isloadingbanner = false
        state.isloadingbannerp = false
        if(action.payload.items.length > 0) {
          state.showpop = true
          state.banneritemspup = action.payload.items
        }else{
          state.banneritemspup = []
        }
        
      })
      
      .addCase(getwebinfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getwebinfo.fulfilled, (state,action) => {
        state.isLoading = false
        state.webinfo = action.payload.item
      })
      .addCase(gamedetail.pending, (state) => {
        state.isLoading = true
        state.gamedetailitems = []
       
      })
      .addCase(gamedetail.fulfilled, (state,action) => {
        state.isLoading = false
        state.gamedetailitems = action.payload.items
        state.headphoto = action.payload.headphoto
      })
      .addCase(promotionlist.pending, (state) => {
        state.isLoading = true
        state.bonusitems = []
       
      })
      .addCase(promotionlist.fulfilled, (state,action) => {
        state.isLoading = false
        state.bonusitems = action.payload.items
      })
      .addCase(promotionlistlogin.pending, (state) => {
        state.isLoading = true
        state.bonusitems = []
       
      })
      .addCase(promotionlistlogin.fulfilled, (state,action) => {
        state.isLoading = false
        if(action.payload.errcode === 401) {
          state.isLogged = false;
          localStorage.removeItem('access_token')
          localStorage.removeItem('username')
        } else {
          state.bonusitems = action.payload.items
        }
        
      })
      
      .addCase(gamelist.pending, (state) => {
        state.isLoading = true
      })
      .addCase(gamelist.fulfilled, (state,action) => {
        state.isLoading = false
        state.gameitems = action.payload.item
      })
      .addCase(gethistory.pending, (state) => {
        state.isLoadinghis = true
        state.meta = {total:1}
      })
      .addCase(gethistory.fulfilled, (state,action) => {
        state.isLoadinghis = false
        if(action.payload.tab == 'bet') {
          state.betitems = action.payload.item
        }else{
          state.deposititems = action.payload.item
        }
        
        state.meta = action.payload.meta
      })
      .addCase(opengame.pending, (state) => {
        state.isloadinggame = true
        state.notdeposit = false
      })
      .addCase(opengame.fulfilled, (state,action) => {
        if(action.payload.errcode === 401) {
          state.isloadinggame = false
          state.isLogged = false;
          localStorage.removeItem('access_token')
          localStorage.removeItem('username')
        } else if(action.payload.errcode === 425) {
          state.isloadinggame = false
          state.notdeposit = true
          
        } else {
          //console.log('action.payload.item.htmlurl', action.payload.item.htmlurl)
          //window.open(action.payload.item.htmlurl)
         if(action.payload.item.pgtype == 1) {
          state.htmlall = action.payload.item.openurl
         }else{
          window.location.href = `${action.payload.item.openurl}`
         }
          //window.location.href = `${action.payload.item.openurl}`
          
          state.isloadinggame = false
          state.notdeposit = false
        }
       
      })
      .addCase(bankdeposit.pending, (state) => {
        state.isLoading = true
        state.statusbank = 2
      })
      .addCase(bankdeposit.fulfilled, (state,action) => {
        state.isLoading = false
        if(action.payload.errcode === 401) {
          state.isLogged = false;
          localStorage.removeItem('access_token')
          localStorage.removeItem('username')
        } else {
          state.bankitems = action.payload.items
          state.currency = action.payload.item
          state.statusbank = 3
        }
       
      })
      .addCase(memberregister.pending, (state) => {
        state.isLoadingLogin = true
      })
      .addCase(memberregister.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        state.errcode = action.payload.errcode
        if(action.payload.item.access_token) {
          localStorage.setItem('access_token', action.payload.item.access_token)
          localStorage.setItem('username', action.payload.item.username)
          window.location.assign("/")
        }
      })
      .addCase(bankregister.pending, (state) => {
        state.isLoadingLogin = true
      })
      .addCase(bankregister.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        state.bankregis = action.payload.item
      })
      .addCase(createqrcode.pending, (state) => {
        state.isLoadingLogin = true
        state.depositstep = 1
        state.slipexten = null
        state.slipphoto = null
      })
      .addCase(createqrcode.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        state.depositstep = 2
        state.headstep = 3
        state.accountdeposit = {status:0, depositstep:2}
        if(action.payload.defaultValues.qrcodetype == 1) {
  
          if(action.payload.defaultValues.gwayz.length > 0){
            window.location.href = `${action.payload.defaultValues.gwayz}`
            //state.accountdeposit = {}
            //state.gwayz = `${action.payload.defaultValues.gwayz}`
          } else {
            
            state.accountdeposit = action.payload.defaultValues
          }
     
        } else if(action.payload.defaultValues.qrcodetype == 2){
          window.location.reload()
        } else {
          console.log('step3')
          const ndata = {depositbet:1, WEBTOKEN}
          nio.emit("sentchat", ndata)
        }
       
      })
      .addCase(checkqrcode.pending, (state) => {
        state.isLoadingLogin = true
        state.depositstep = 1
        state.accountdeposit = {}
      })
      .addCase(checkqrcode.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        if(action.payload.errcode === 401) {
          state.isLogged = false;
          localStorage.removeItem('access_token')
          localStorage.removeItem('username')
        } else {
          state.depositstep = 3
          state.accountdeposit = action.payload.item
        }
      })
      .addCase(checkcashback.pending, (state) => {
        state.isLoading = true
      })
      .addCase(checkcashback.fulfilled, (state,action) => {
        state.isLoading = false
        state.cashback = action.payload.item
      })
      .addCase(checkcommission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(checkcommission.fulfilled, (state,action) => {
        state.isLoading = false
        state.commission = action.payload.item
      })
      .addCase(cashbackwithdraw.pending, (state) => {
        state.btnLoading = true
      })
      .addCase(cashbackwithdraw.fulfilled, (state,action) => {
        state.btnLoading = false
        state.cashbackalert = true
        state.item = {...state.item, ...action.payload.item}
        state.cashback = {...state.cashback, ...action.payload.cashback}
      })
      .addCase(commissionwithdraw.pending, (state) => {
        state.btnLoading = true
      })
      .addCase(commissionwithdraw.fulfilled, (state,action) => {
        state.btnLoading = false
        state.commissionalert = true
        state.item = {...state.item, ...action.payload.item}
        state.commission = {...state.commission, ...action.payload.commission}
      })
      .addCase(affwithdraw.pending, (state) => {
        state.btnLoading = true
      })
      .addCase(affwithdraw.fulfilled, (state,action) => {
        state.btnLoading = false
        state.cashbackalert = true
        state.affitem = {...state.affitem, ...action.payload.item}
      })
      .addCase(checkwithdraw.pending, (state) => {
        state.isLoadingLogin = true
      })
      .addCase(checkwithdraw.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        if(action.payload.errcode === 401) {
          state.isLogged = false;
          localStorage.removeItem('access_token')
          localStorage.removeItem('username')
        } else {
          state.accountwithdraw = action.payload.item
        }
        
      })
      .addCase(confirmqrcode.pending, (state) => {
        state.isLoadingLogin = true
      })
      .addCase(confirmqrcode.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        state.accountdeposit = action.payload.item
        const ndata = {depositbet:1, WEBTOKEN}
        nio.emit("sentchat", ndata)
      })
      .addCase(confirmqrcodepost.pending, (state) => {
        state.isLoadingLogin = true
      })
      .addCase(confirmqrcodepost.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        state.accountdeposit = action.payload.item
        const checkusername = action.payload.items_auto.find(x=>`${x.username}` === `${localStorage.getItem('username')}`)
        if(checkusername) {
           // alert('ยอดฝากได้รับการยืนยัน')
            state.accountdeposit = { ...action.payload.item, checkfinish:'finish', status:3,depositstep:1}
            state.headstep = 1
            state.depositstep = 1
          
        }else{
          state.accountdeposit = { ...action.payload.item,checkfinish:'finish', status:4, depositstep:1}
          state.headstep = 1
          state.depositstep = 1
          //alert('ไม่พบยอดเงิน')
        }
        const ndata = {depositbet:1, WEBTOKEN}
        nio.emit("sentchat", ndata)
      })
      .addCase(rejectqrcodepost.pending, (state) => {
        state.isLoadingLogin = true
      })
      .addCase(rejectqrcodepost.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        state.accountdeposit = {depositstep:1}
        state.headstep = 1
        state.depositstep = 1
        
      })
      .addCase(memberwithdraw.pending, (state) => {
        state.isLoadingLogin = true
        state.errcode = 0
      })
      .addCase(memberwithdraw.fulfilled, (state,action) => {
        state.isLoadingLogin = false
        if(action.payload.errcode == 0){
          state.accountwithdraw = action.payload.item
          const ndata = {depositbet:1, WEBTOKEN}
          nio.emit("sentchat", ndata)
        }else{
          state.errcode = action.payload.errcode
        }
      })
  }
})

// Actions
export const {login, logout,setnotdeposit,closecashbackalert,closecommissionalert,getusername,setPromotion, setAffcode, setslipexten, setslipphoto, checkmemberlogin, setStep,setBonus, setHeadStep, setAmount, setMtimestamp} = userSlice.actions;
export default userSlice.reducer
