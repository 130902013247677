import React from "react";
import Logo from "./Logo";
import {useSelector} from "react-redux";
import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'ຝາກ/ຖອນ ໄວ',
      2:'ລະດັບ ວິນາທີ',
      3:'ບໍລິການ',
      4:'ບໍລິການ 24 ຊົ່ວໂມງ.',
      5:'ຝາກເງິນງ່າຍດາຍ ຜ່ານຍູມັນນີ້',
      6:'ຮອງຮັບຍູມັນນີ້',
      7:'ເຮົາແມ່ນທີ່ໃຫ້ຫຼີ້ນແທງບານອອນລາຍໃນໂທລະສັບ ແລະ ໃນຄອມ ມີ ບາຄາລ້າ ເກມສະລ໋ອດ ເສືອ ມັງກອນ ມີໃຫ້ຫຼີ້ນຫຼາຍເກມ ສາມາດເລືອກຫຼີ້ນໄດ້ຫຼາຍຮູບແບບ  ເປັນປະສົບການຕໍ່ຜູ້ຫຼີ້ນ ທີ່ສະດວກ ແລະ ວ່ອງໃວ ຮອງຮັບການຫຼີ້ນໃນລະບົບໂທລະສັບທີ່ດີທີ່ສຸດ',
      8:'www.user44.com',
      9:'ຄາສິໂນອອນໄລນ໌ໃໝ່ ທີ່ໝັ້ນຄົງປອດໄພ',
      10:'ເປີດໂອກາດໃຫ້ຜູ້ຫຼິ້ນເຂົ້າມາຮ່ວມວາງເດີມພັນກັບຄາສິໂນອອນໄລນ໌ໃໝ່ໄດ້ຫຼາກຫຼາຍຮູບແບບດ້ວຍກັນ ບໍ່ວ່າຈະເປັນ ບາຄາລ່າ ເສືອມັງກອນ ໄຮໂລ ລູເລັດ ນ້ຳເຕົ້າປູປາ ກຳຖົ່ວ ຖ່າຍທອດສົດ ສົ່ງຕົງຈາກຄາສິໂນຈິງ',
      11:'ເຫັນທຸກມຸມຂອງການເດິມພັນ ເບິ່ງແຍງຄວາມປອດໄພຂອງຜູ້ໃຊ້ບໍລິການດ້ວຍໂປຣແກມເມີ້ມືອາຊີບ ມີລະບົບກວດສອບດູແລພ້ອມທີ່ຈະເຮັດໃຫ້ຜູ້ໃຊ້ບໍລິການໝັ້ນໃຈຖຶງຄວາມປອດໄພ',
      12:'ກວດສອບຜົນການເດີມພັນຢ່າງກົງໄປກົງມາ ທຸກລະບົບການເຮັດທຸລະກຳທາງການເງິນອັບເກດໃຫ້ໄວຍິ່ງຂື້ນ ຝາກ-ຖອນ ດ້ວຍເວລາພຽງ4-5ວິນາທີ ເຈົ້າກໍ່ສາມາດຮັບຜົນກຳໄລທີ່ເປັນຂອງເຈົ້າໄດ້ເລີຍທັນທີ',
      13:'ການເງິນ ປອດໄພ',
      14:'ຝາກ ຖອນ 24 ຊົ່ວໂມງ.',
    },
    thai:{
      1:'ฝากถอนเร็ว',
      2:'ผ่าน Thai QR Prompay เพียง 1 วินาที',
      3:'บริการดีเยี่ยม',
      4:'ดูแลตลอด 24 ชั่วโมง รับรองความปลอดภัย 100%',
      5:'ฝากเงินง่ายๆ ผ่านทรูวอลเล็ต',
      6:'รองรับทรูวอลเลต',
      7:'เราคือที่ให้เล่นแทงบอลออนไลน์ในรูปแบบมือถือ และบนคอมพิวเตอร์ มี บาคาร่า เกมส์สล็อต เสือ มังกร มีให้เล่นมากในเกมส์ สามารถเลือกเล่นได้หลากหลายรูปแบบ ประสบการณ์ต่อผู้เล่น ที่สะดวก เสถียรที่สุด รองรับทั้งมือถือ',
      8:'WWW.GPOWERBETTING.COM',
      9:'ศูนย์รวมความบันเทิงออนไลน์ เต็มรูปแบบ',
      10:'เปิดโอกาสให้ผู้เล่นเข้ามาร่วมวางเดิมพันบนคาสิออนไลน์ใหม่ได้หลากหลายรูปแบบด้วยกัน ไม่ว่าจะเป็น บาคาร่าเสือมังกร ไฮโล รูเล็ต น้ำเต้าปูปลา กำถั่ว ถ่ายทอดสดส่งตรงจากคาสิโนจริง',
      11:'เห็นทุกมุมมองของการเดิมพัน ดูแลความปลอดภัยของผู้ใช้บริการด้วยโปรแกรมเมอร์มืออาชีพ มีระบบตรวจสอบดูแลพร้อมที่จะทำให้ผู้ใช้บริการมั่นใจถึงความปลอดภัย',
      12:'ตรวจสอบผลการเดิมพันอย่างตรงไปตรงมา ทุกระบบการทำธุระกรรมทางการเงินอัพเกรดให้รวดเร็วยิ่งขึ้น ฝาก-ถอน ด้วยเวลาเพียง 1-3 วินาที คุณก็สามารถรับผลกำไรที่เป็นของคุณได้เลยทันที',
      13:'ฝากไม่มีขั้นต่ำ',
      14:'ฝากไม่มีขั้นต่ำ ถอนขั้นต่ำ 100 บาท'
    }
  }
function Service() {
    const user = useSelector(state => state.user)

    return (
        <>
            {!user.isLogged && <>
                <section className="section03">
                    <div className="contain03">
{
    /*
 <div className={'text-center'}>
                            <Logo style={{maxHeight: '70px', marginBottom: '15px'}}/>
                        </div>
    */
}
                       

                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-0 leftsec03">
                                <div className="leftdetailsec03">
                                    <h3 className="text-center">{lgtext[LG]['9']}</h3>
                                    <div className="x-hr-border-glow mb-3"/>

                                    {lgtext[LG]['10']} <br/><br/>

                                    - {lgtext[LG]['11']} <br/>
                                    - {lgtext[LG]['12']} <br/><br/>
                                   
                                </div>
                            </div>
                            <div className="col-12 col-md-6 p-0 rightsec03 flexcenter">
                                {
                                    /*  <img className={'img-fluid img-hero'} src={'./images/wm-casino-info-banner-1-2.png'} alt={'hero'}/>*/
                                }
                                <img className={'img-fluid img-hero'} src={'./images/banner_content_1.png'} alt={'hero'}/>
                            </div>
                            <div className="col-12 p-0">
                                <a className="buttonboxsec03 mb-3" href="/register">
                                    {
                                        LG == 'thai' ? <img src="./images/regisbtn.gif"/> : <img src="./images/user44/regisgifflaos1.gif"/>
                                    }
                                    
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="contain03">
<div className="row m-0">
<div className="col-12 col-md-6 p-0 rightsec03 flexcenter">
       
       <img className={'img-fluid img-hero'} src={'./images/banner_content_3.png'} alt={'hero'}/>
   </div>
    <div className="col-12 col-md-6 p-0 leftsec03">
        <div className="leftdetailsec03">
            <h3 className="text-center">อันดับ 1 เรื่องการเดิมพัน และความเป็นโมเดิร์น</h3>
            <div className="x-hr-border-glow mb-3"/>
            SSWINBET เดิมพันออนไลน์ด้วยความมั่นใจ เว็ปตรงไม่ผ่านเอเยนต์ อันดับ 1 ที่มีผู้ให้ความไว้วางใจมากที่สุดในไทย ซึ่งเป็นที่ยอมรับว่า เราคือเว็ปที่มีความมั่นคง ปลอดภัยสูง ได้มาตรฐานสากล พร้อมทั้งตอบสนองความต้องการของผู้เล่น ดูแลและใส่ใจสมาชิกเดิมพันทุกท่าน ทั้งสมาชิกเก่าและสมาชิกใหม่ ตั้งแต่ระบบฝากถอนที่ให้บริการอย่างรวดเร็วไม่กี่วินาที เพื่อให้ท่านไม่ต้องเสียเวลาในการรอทำรายการและให้ท่านได้สนุกกันอย่างต่อเนื่องไม่จำกัด หมดทุกข้อกังวลเรื่องการโกงเงิน เพราะเราถือได้ว่าเป็น #1 ของโลกพนันออนไลน์
            เล่นกับเราผ่าน 2 ช่องทาง ไม่ว่าจะบนเว็บไซต์ หรือ บนระบบออโต้ไลน์ สะดวกไม่ต้องล๊อกอินให้ยุ่งยาก <br/><br/>
            <div className="-ic-section02 p-1">
            <span><img src="./images/dot2.png" className="dot3"/></span><span>เล่นผ่านเว็บก็ได้</span>
                </div>
                <div className="-ic-section02 p-1">
            <span><img src="./images/dot2.png" className="dot3"/></span><span>เล่นผ่านออโต้ไลน์ก็สดวก ไม่ต้องล๊อกอิน</span>
                </div>
                <div className="-ic-section02 p-1">
            <span><img src="./images/dot2.png" className="dot3"/></span><span>ครบจบในที่เดียวเท่านั้น</span>
                </div>
           
           
        </div>
    </div>
   
    <div className="col-12 p-0">
        <a className="buttonboxsec03 mb-3" href="/register">
            {
                LG == 'thai' ? <img src="./images/regisbtn.gif"/> : <img src="./images/user44/regisgifflaos1.gif"/>
            }
            
        </a>
    </div>
</div>
</div>

                    <div className="x-service-wrapper mt-3">
                        <div className="row container m-auto justify-content-center bgsec02 pe-4">
                            <div
                                className="col-12 col-sm-6 col-md-3 mb-lg-0 mb-2 text-center -box d-flex align-items-start d-md-block -box">
                                <a href="" className="d-flex flex-md-column flex-row" data-toggle="modal"
                                   data-target="#registerModal">
                                    <div className="-fake-bg"/>
                                    <div className="-ic-wrapper">
                                        <div className="-ic-section02">
                                            <img src="./images/icon/04.png"/>
                                        </div>
                                    </div>
                                    <div className="text-start text-md-center">
            <span className="d-block  text-muted-lighter Detailsec02 f-5">
              <span className="-title-h2"> {lgtext[LG]['1']}</span>
              <div className="x-hr-border-glow mb-3"/>
              <div className="textwidget"> {lgtext[LG]['2']}</div>
            </span>
                                    </div>
                                </a>
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-3 mb-lg-0 mb-2 text-center -box d-flex align-items-start d-md-block -box">
                                <div className="-fake-bg"/>
                                <div className="-ic-wrapper">
                                    <div className="-ic-section02">
                                        <img src="./images/icon/05.png"/>
                                    </div>
                                </div>
                                <div className="text-start text-md-center">
          <span className="d-block  text-muted-lighter Detailsec02 f-5">
            <span className="-title-h2">{lgtext[LG]['13']}</span>
            <div className="x-hr-border-glow mb-3"/>

            <div className="textwidget">{lgtext[LG]['14']}</div>
          </span>
                                </div>
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-3 mb-lg-0 mb-2 text-center -box d-flex align-items-start d-md-block -box">
                                <div className="-ic-wrapper">
                                    <div className="-ic-section02">
                                        <img src="./images/icon/06.png"/>
                                    </div>
                                </div>
                                <div className="text-start text-md-center">
          <span className="d-block  text-muted-lighter Detailsec02 f-5">
            <span className="-title-h2">{lgtext[LG]['3']}</span>
            <div className="x-hr-border-glow mb-3"/>

            <div className="textwidget">{lgtext[LG]['4']}</div>
          </span>
                                </div>
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-3 mb-lg-0 mb-2 text-center -box d-flex align-items-start d-md-block -box">
                                <div className="-ic-wrapper">
                                    <div className="-ic-section02">
                                        <img src="./images/icon/truewallet.png"/>
                                    </div>
                                </div>
                                <div className="text-start text-md-center">
          <span className="d-block  text-muted-lighter Detailsec02 f-5">
            <span className="-title-h2">{lgtext[LG]['6']}</span>
            <div className="x-hr-border-glow mb-3"/>

            <div className="textwidget">{lgtext[LG]['5']}</div>
          </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className={'gold-line'}/>
            </>
            }
        </>
    );
}

export default Service;
