import { useEffect, useState, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {bankdeposit, createqrcode, checkqrcode, confirmqrcodepost, rejectqrcodepost, promotionlistlogin, setStep, setHeadStep, setAmount, setBonus} from "../store/user";
import { checkcurrency, addCommas } from '../config/Index'
import ModalLoad from '../components/ModalQr'
import ModalLoading from '../components/ModalLoading'
import ModelApprove from '../components/ModelApprove'
import ModelApproveAuto from '../components/ModelApproveAuto'
import ModalPaymentGateway from '../components/ModalPaymentGateway'
import Profice from './Pedding'
import Showqrcode from './Showqrcodeth'
import BonusItems from './BonusItems'
import WithdrawPro from './DepositPro'
import { message } from 'antd'

function Deposit() {
   
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector(state => state.user)
    const { headstep, amount, bonus:bonusredux } = user
   
    const [type, setType] = useState(2)
    const [btnphoto, setbtnphoto] = useState("")
    const [bonus, setbonus] = useState("0")
    useEffect(()=> {
        dispatch(setHeadStep(1))
        if(user.isLogged === true) {
            dispatch(bankdeposit({}))
            dispatch(checkqrcode({}))
            dispatch(promotionlistlogin({}))
            //message.success('ฝากเงินเรียบร้อย')
        }else{
            navigate('/')
        }
    }, [])

    const [banks, setbank] = useState([]);


    const [trueWallet, settrueWallet] = useState([]);

    const setTypeBank = (type, items) => {
        setType(type)
        if(type === 2) {
            setbank(items)
            const fkbank = items[0].fkbank
            setbtnphoto(`th-${fkbank}`)
        }
        if(type === 1) {
            settrueWallet(items)
            const fkbank = items[0].fkbank
            setbtnphoto(`th-${fkbank}`)
        }
    }

    useMemo(()=> {
        if(user?.statusbank === 3) {
            const itemsnew = user.bankitems.find(x=>x.banktype == 2)
            const fkbank = itemsnew?.items[0]?.fkbank
            setbtnphoto(`th-${fkbank}`)
            setbank(itemsnew?.items)
        }
    }, [user?.statusbank])
    const txtamountd = useRef(null)
    const depositSubmit = () => {
        const amount = txtamountd.current.value
        if(!amount){
            txtamountd.current.focus()
        }else{
            const data = {
                amount,
                bankdata:btnphoto,
                bonus:bonus
            }
            dispatch(createqrcode(data))
        }
    }

    const depositConfirm = () => {
        dispatch(confirmqrcodepost({sid:user?.accountdeposit?.sid,
            photo_ex:user?.slipexten,
            photo:user?.slipphoto,
        }))
    }

    const depositReject = () => {
        dispatch(rejectqrcodepost({sid:user?.accountdeposit?.sid,
            photo_ex:user?.slipexten,
            photo:user?.slipphoto,
            status:5
        }))
    }

    const depositCreateQr = () => {
        const { amount, bonus } = user
        const data = {
            amount,
            bankdata:'0',
            fkpromotion:bonus
        }
        dispatch(createqrcode(data))
    }
  
    const depositSubmitStep1 =()=> {
        const amount = txtamountd.current.value
       
        if(!amount) {
            txtamountd.current.focus()
        } else {
            let bonusinit = bonusredux ? bonusredux : '0'
            dispatch(setHeadStep(2))
            dispatch(setAmount(amount))
            dispatch(setBonus(bonusinit))
        }
        
    }

    const changeStep =(id)=> {
        dispatch(setHeadStep(id))
        dispatch(setAmount(0))
    }

    const addAmount = (amount) => {
        dispatch(setAmount(amount))
        txtamountd.current.value = amount
    }

    return (
        <div>
            <div className="headsecion">
                <img src="/images/icon/deposit.png"/> ฝากเงิน
            </div>
         
            <div className="ctpersonal">
                <div className="smallcontain">
                    <div className="row m-0 mt-4">
                       

                        <div className="col-12 p-0">
                            <div className="tab-content" id="v-pills-tabContent">


                                {
                                    user?.accountdeposit?.depositstep == 1 && <div className='p-2'>
                                        <div className="row m-0 mt-2">

<div className="col-4 p-0 text-center">
                        <div className={`${headstep === 1 ? `btnactive3` : `btnactive2`}`} onClick={()=>changeStep(1)} >{amount > 0 && headstep > 1 ? `ยอด ${addCommas(parseFloat(amount).toFixed(0))}`:'1. ใส่จำนวนเงิน'}</div>
                    </div>
                    <div className="col-4 p-0 text-center">
                     <div className={`${headstep === 2 ? `btnactive3` : `btnactive2`}`} >2. รับโบนัส</div>
                    </div>
                    <div className="col-4 p-0 text-center">
                    <div className={`${headstep === 3 ? `btnactive3` : `btnactive2`}`}>3. สแกนจ่าย</div>
                    </div>
                                </div>
                                {
                                    headstep === 1 && <>
                                     <div className={'row pt-5'}>
                                        <div className={'col'}>
                                        จำนวนเงินที่ต้องการฝาก
                                        </div>
                                        <div className={'col-auto'}>
                                            ({btnphoto && checkcurrency(btnphoto.split("-")[0])})
                                        </div>
                                    </div>
                                            <div className={'row align-items-center'}>
                                            <form>
                                    <div className={'col-auto'}>
                                       
                                    </div>
                                    <div className={'col p-2'}>
                                        <input ref={txtamountd} className="inputmain" type="number" placeholder="กรุณาป้อนจำนวนเงิน"/>
                                    </div>
                
                                    </form>
                                </div>
                                <hr/>
                                <div className='d-flex justify-content-around money1'>
                                    <button onClick={() => addAmount(100)}>100</button>
                                    <button onClick={() => addAmount(300)}>300</button>
                                    <button onClick={() => addAmount(500)}>500</button>
                                 
                                </div>
                                <div className='d-flex justify-content-around pt-2 money1'>
                                 
                                    <button onClick={() => addAmount(1000)}>1,000</button>
                                    <button onClick={() => addAmount(5000)}>5,000</button>
                                    <button onClick={() => addAmount(10000)}>10,000</button>
                                </div>
                                <button type="button" onClick={depositSubmitStep1} className="moneyBtn">แจ้งฝากเงิน</button>
                                    </>
                                }
                                   
                                    </div>
                                }
                                {
                                    headstep === 2 && <BonusItems depositConfirm={depositCreateQr} />
                                }

                                 {
                                     // กรณีติดโปร
                                     user?.accountdeposit?.stepwithdraw == 4 && user?.accountdeposit?.statuspro == 'running' && user?.accountdeposit?.activepass == 'no' && <WithdrawPro type='promotion' text='ยังไม่สามารถฝากเพิ่มได้ กรุณาทำยอดให้ครบตามโปรโมชั่น' />
                                }

                                {
                                     // กรณีไม่มีโปร แต่ติดยอดเทิน
                                     user?.accountdeposit?.stepwithdraw == 4 && user?.accountdeposit?.statuspro == 'nopro' && user?.accountdeposit?.activepass == 'no' && <WithdrawPro text={`กรุณาทำยอดเทิร์น ${user?.accountdeposit?.minturnover} เท่า ของยอดฝาก`} />
                                }

                                {
                                     // กรณีติดโปร ผ่านแล้วแต่ยังไม่ถอน
                                     user?.accountdeposit?.stepwithdraw == 1 && user?.accountdeposit?.activepass == 'no' && <WithdrawPro text='ยังไม่สามารถฝากเพิ่มได้ กรุณาแจ้งปลดล๊อคโปร' />
                                }

                                {
                                     user?.accountdeposit?.depositstep == 4 && user?.accountdeposit?.status == 2 && <Profice />
                                }

                                {
                                    user?.accountdeposit?.depositstep == 3 && user?.accountdeposit?.status == 2 && <Showqrcode depositConfirm={depositConfirm} depositReject={depositReject} />
                                }
                        
                       
                            </div>
                        </div>
                    </div>
                                {
                                    /*
 <div className="modalspanbox mt-5 pt-5">
                        <span>กรุณาโอนมาจากบัญชีที่ลงทะเบียนเท่านั้น</span><br/>
                        หากลูกค้าฝากเงินสด ผ่านตู้ หรือ ช่องทางอื่น
                        กรุณาติดต่อแอดมินทุกกรณี
                    </div>
                                    */
                                }
                   
                </div>

            </div>
            <ModalLoad show={user?.accountdeposit?.depositstep == 2 && user?.accountdeposit?.status == 0} onHide={()=>{}}/>
            <ModelApprove show={user?.accountdeposit?.checkfinish == 'finish'} status={user?.accountdeposit?.status} onHide={()=>{ dispatch(setStep({accountdeposit:{depositstep:1}}))}} />
            
            <ModalLoading show={user?.isLoadingLogin} onHide={()=>{}} />
            <ModalPaymentGateway show={user?.gwayz.length > 0} onHide={()=>{}} />

        </div>
    );
}

export default Deposit;
