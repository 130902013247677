import React, {useState} from "react";
import { useSelector, useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom'
import { opengame } from '../store/user'
import ModalLoad from './Modal'
import { LG } from '../config/Index'

const lgtext = {
    lao:{
      1:'ກິລາ',
      2:'ຄາສິໂນ',
      3:'ສະລ໊ອດ',
      4:'ເກມບັດ',
      5:'ເກມຍິງປາ',
      6:'ປະຫວັດ',
      7:'ໂປໂມຊັ້ນ',
      8:'ອອກ​ຈາກ​ລະ​ບົບ',
      9:'ຝາກເງິນ',
      10:'ຖອນເງິນ',
      11:'USER44 ເວປໄຊ້ຜູ້ໃຫ້ບໍລິການເດິມພັນຕ່າງໆຈາກທົ່ວໂລກ ຕະຫຼອດ 24 ຊົ່ວໂມງ',
    },
    thai:{
      1:'กีฬา',
      2:'คาสิโน',
      3:'สล๊อต',
      4:'เกมส์ไพ่',
      5:'ยิงปลา',
      6:'ประวัติ',
      7:'โปรโมชั่น',
      8:'ออกจากระบบ',
      9:'ฝากเงิน',
      10:'ถอนเงิน',
      11:'GPower เว็บเดิมพันออนไลน์ 24 ชม',
    }
  }
function Partners() {
    const [menu, setMenu] = useState('casino');
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const gameurl = (fkprovider, {lobby, code}) => {
      
        if(parseInt(lobby) === 0) {
            if(user.isLogged) {
                dispatch(opengame({fkprovider}))
            } else {
                navigate(`/login`)
            }
            
        } else {
            if(user.isLogged) {
                navigate(`/${code}`)
            } else {
                navigate(`/login`)
            }
           
        }
        
    }

    const gameurldetail = (fkprovider, {gameid}) => {
      
        if(user.isLogged) {
            dispatch(opengame({fkprovider, gameid}))
        } else {
            navigate(`/login`)
        }
        
    }

    const opengclub = () => {
        if(user.isLogged) {
            dispatch(opengame({fkprovider:'lotto'}))
        } else {
            navigate(`/login`)
        }
    }

    return (
        <div className={''}>

            <section className="section02">
                <div className="contenttabsec02">
                    <div className="tabctsec02">
                        <div className="row m-0 ">
                      
                        <div className="col px-1">
                                <div className={`tablinks ${menu === 'sport' ? 'active' : ""} `}>
                                    <button onClick={() => setMenu('sport')}>
                                        <img src="images/icon/sport.png"/>
                                        <span>{lgtext[LG]['1']}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col px-1">
                                <div className={`tablinks ${menu === 'casino' ? 'active' : ""} `}>
                                    <button onClick={() => setMenu('casino')}>
                                        <img src="images/icon/casino.png"/>
                                        <span>{lgtext[LG]['2']}</span>
                                    </button>
                                </div>
                            </div>
                            
                            <div className="col px-1">
                                <div className={`tablinks ${menu === 'slot' ? 'active' : ""} `}>
                                    <button onClick={() => setMenu('slot')}>
                                        <img src="images/icon/slot.png"/>
                                        <span>{lgtext[LG]['3']}</span>
                                    </button>
                                </div>
                            </div>
                           
                
                            <div className="col px-1">
                                <div className={`tablinks ${menu === 'fish' ? 'active' : ""} `}>
                                    <button onClick={() => setMenu('fish')}>
                                        <img src="images/icon/fish.png"/>
                                        <span>{lgtext[LG]['5']}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col px-1">
                                <div className={`tablinks ${menu === 'glcub' ? 'active' : ""} `}>
                                    <button onClick={() => opengclub('sport')}>
                                        <img src="images/lotto.png"/>
                                        <span>หวย</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'gold-line'}/>

                <div className="contain02">
                    <div className="tabcontent" style={{display: menu === 'casino' ? "block" : "none"}}>
                        <div className="gridgame alone">
                            {user?.gameitems && user?.gameitems.filter(x=>x.type==2).map((partner, index) => {
                                return <div className="ingridgame" key={`partner-${index}`}>
                                    <div className="iningridgame" onClick={()=>gameurl(partner.fkprovider, partner)}>
                                        <img src={partner.image} style={{ maxHeight:165}}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>

                    <div className="tabcontent" style={{display: menu === 'sport' ? "block" : "none"}}>
                        <div className="gridgame alone">
                            {user?.gameitems && user?.gameitems.filter(x=>x.type==1).map((partner, index) => {
                                return <div className="ingridgame" key={`partner-${index}`}>
                                    <div className="iningridgame" onClick={()=>gameurl(partner.fkprovider, partner)}>
                                        <img src={partner.image} style={{ maxHeight:165}}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>

                    <div className="tabcontent" style={{display: menu === 'slot' ? "block" : "none"}}>
                        <div className="gridgame alone">
                            {user?.gameitems && user?.gameitems.filter(x=>x.type==3).map((partner, index) => {
                                return <div className="ingridgame" key={`partner-${index}`}>
                                    <div className="iningridgame" onClick={()=>gameurl(partner.fkprovider, partner)}>
                                        <img src={partner.image}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>

                    <div className="tabcontent" style={{display: menu === 'fish' ? "block" : "none"}}>
                        <div className="gridgame alone">
                            {user?.gameitems && user?.gameitems.filter(x=>x.fish==1).map((partner, index) => {
                                return <div className="ingridgame" key={`partner-${index}`}>
                                    <div className="iningridgame" onClick={()=>gameurl(partner.fkprovider, partner)}>
                                        <img src={partner.image}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>


                    <div className="tabcontent" style={{display: menu === 'dice' ? "block" : "none"}}>
                    <div className="gridgame alone">
                            {user?.gameitems && user?.gameitems.filter(x=>x.type==5).map((partner, index) => {
                                return <div className="ingridgame" key={`partner-${index}`}>
                                    <div className="iningridgame" onClick={()=>gameurldetail(partner.fkprovider, partner)}>
                                        <img src={partner.image}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <ModalLoad show={user?.isloadinggame} onHide={()=>{}}/>
        </div>


    );
}

export default Partners;
