import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Accordion, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {useState} from "react";
import { promotionlist, setPromotion, setBonus } from "../store/user";
import { LG, APIURL } from '../config/Index'

const lgtext = {
    lao:{
      1:'ໂປໂມຊັ້ນ',
      2:'ຮັບເຄດິດ',
      3:'ເມື່ອໂອນແລ້ວກົດປຸ່ມ "ຍືນຢັນຝາກ" ດ້ານລຸ່ມ',
      4:'ຍືນຢັນຝາກ',
    },
    thai:{
      1:'กิจกรรมฟรีเครดิต',
      2:'รับเครดิต',
      3:'เมื่อโอนแล้วกรุณากด "ยืนยัน" ด้านล่าง',
      4:'ยืนยันฝาก',
    }
  }
function Promotion() {
    const dispatch = useDispatch()
    useEffect(()=> {
        dispatch(promotionlist({}))
    }, [])

    const navigate = useNavigate()

    const savepro = (id) => {
        dispatch(setBonus(id))
        navigate('/deposit')
    }

    //const {  bonusitems } = useSelector(state=>state.user)
   
    const [bonusitems] = useState([
        {name: 'banner5', image: './images/gg5.png'},
        {name: 'banner2', image: './images/gg3.png'},
       // {name: 'banner3', image: './images/gg4.png'},
        {name: 'banner1', image: './images/gg2.png'},
       
       // {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];
     
    /*
    const [banners] = useState([
        //{name: 'banner1', image: './images/banner/01.jpg'},
       // {name: 'banner2', image: './images/banner/02.jpg'},
       // {name: 'banner3', image: './images/banner/03.jpg'},
        {name: 'banner4', image: './images/banner/04.jpg'},
       // {name: 'banner5', image: './images/promotion/pro4.png'}
    ]) || [];
    */
    return (
        <div>
            <div className="headsecion">
                <i className="far fa-gift"/> {lgtext[LG]['1']}
            </div>

            <div className="ctpersonal promotion">
                <div className="gridingame full">

                    {bonusitems && bonusitems.map(banner => {
                        return <div className="ingridgame " key={banner.name}>
                            <div className="iningridgame pro">
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className={'p-0 m-0'}>
                                            <img className="accordion" src={`${banner.image}`}
                                                 alt={banner.name}/>
                                        </Accordion.Header>
                                        {
                                             banner.name == 'banner1' && <Accordion.Body>
                                                <div className="text-center pt-2">✅ เงื่อนไขรับเครดิตฟรี ✅ </div>

                                             
                                             ‼️ ฝากขั่นต่ำ 100 <br />
                                             ‼️ รับสิทธิรีวิวได้ 1 ครั้ง ต่อวัน <br />
                                             ‼️ รับฟรีเครดิต 10 เครดิต<br />
                                            
                                             <div className="text-center pt-2"><button type="button" onClick={()=>savepro(banner.id, banner.pbonus)} className="btnLogin">พบกันเร็วๆนี้</button></div>
                                        </Accordion.Body>
                                        }

{
                                             banner.name == 'banner5' && <Accordion.Body>
                                                <div className="text-center pt-2">✅ เงื่อนไขรับเครดิตฟรี ✅ </div>

                                             
                                             ‼️ ทายสกอร์ เจ้าบ้าน ทีมเยือน (เช่น 2-0) <br />
                                             ‼️ รับสิทธิทายผลทุกวัน <br />
                                             ‼️ ทายถูก 1 คู่รับ 1,000 เครดิต<br />
                                             ‼️ ทายถูก 3 คู่รับ 5,000 เครดิต<br />
                                            
                                             <div className="text-center pt-2"><button type="button" onClick={()=>savepro(banner.id, banner.pbonus)} className="btnLogin">พบกันเร็วๆนี้</button></div>
                                        </Accordion.Body>
                                        }

{
                                             banner.name == 'banner2' && <Accordion.Body>
                                                <div className="text-center pt-2">✅ เงื่อนไขรับเครดิตฟรี ✅ </div>

                                             ‼️ ทำอันดับยอด TurnOver สูงสุด 1-20 อันดับแรก <br />
                                             ‼️ ในระหว่างรอบสัปดาห์ (จันทร์ - อาทิตย์) <br />
                                             ‼️ รับฟรีเครดิตทุกวันจันทร์ เวลา 14:00 น เป็นต้นไป<br />
                                             ‼️ มูลค่ารางวัลรวมกว่า 100,000 บาท<br />
                                            
                                             <div className="text-center pt-2"><button type="button" onClick={()=>savepro(banner.id, banner.pbonus)} className="btnLogin">พบกันเร็วๆนี้</button></div>
                                        </Accordion.Body>
                                        }
                        
                                        
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    })}

                </div>
            </div>
        </div>
    );
}

export default Promotion;
